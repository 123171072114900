export default function characterPopup(item) {
    const popupButton = item;
    const settings = JSON.parse(item.getAttribute('data-settings'));

    if (settings === null || settings === undefined) return;

    let popUp = document.querySelector('.popup-character');
    const footer = document.querySelector('.footer');

    const { image_url, name, position, description } = settings;

    const createElementWithClass = (tag, elementClass) => {
        const element = document.createElement(tag);
        element.classList.add(...elementClass);
        return element;
    };

    const createPopUp = () => {
        const popupElement = createElementWithClass('div', ['popup-character']);
        const popupElementOverlay = createElementWithClass('div', [
            'popup-character__overlay',
        ]);
        popupElement.appendChild(popupElementOverlay);

        const popupElementContent = createElementWithClass('div', [
            'popup-character__content',
        ]);

        const closePopup = createElementWithClass('i', ['icon']);
        closePopup.classList.add('icon-close', 'popup-character__close');
        popupElementContent.appendChild(closePopup);

        const popupContentInnerEl = createElementWithClass('div', [
            'popup-character__inner',
        ]);

        if (image_url) {
            const image = createElementWithClass('img', [
                'popup-character__img',
            ]);
            image.setAttribute('src', image_url);
            popupElementContent.appendChild(image);
        }

        if (name) {
            const characterName = createElementWithClass('p', [
                'popup-character__name',
                'typo-h3',
                'f-700',
            ]);
            characterName.textContent = name;
            popupContentInnerEl.appendChild(characterName);
        }

        if (position) {
            const characterPosition = createElementWithClass('p', [
                'popup-character__position',
                'typo-24',
                'color-gray-120',
            ]);

            characterPosition.innerHTML = position;
            popupContentInnerEl.appendChild(characterPosition);
        }

        if (description) {
            const characterDescription = createElementWithClass('div', [
                'popup-character__description',
                'typo-18',
                'color-gray-120',
            ]);
            characterDescription.innerHTML = description;
            popupContentInnerEl.appendChild(characterDescription);
        }

        popupElementContent.appendChild(popupContentInnerEl);
        popupElement.appendChild(popupElementContent);
        footer.appendChild(popupElement);
        popUp = document.querySelector('.popup-character');

        setTimeout(() => {
            popUp.classList.add('active');
        }, 100);
    };

    const closePopUp = () => {
        popUp.classList.remove('active');
        console.log(footer);
        setTimeout(() => {
            footer.removeChild(popUp);
            popUp = document.querySelector('.popup-character');
        }, 500);
    };

    const handlePopUp = e => {
        e.preventDefault();
        console.log(e.currentTarget);
        if (!popUp) createPopUp();

        const popupCloseButton = popUp.querySelector('.popup-character__close');
        const popupOverlay = popUp.querySelector('.popup-character__overlay');

        popupCloseButton.addEventListener('click', closePopUp);
        popupOverlay.addEventListener('click', closePopUp);
    };

    if (popupButton) popupButton.addEventListener('click', e => handlePopUp(e));
}
