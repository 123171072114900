export default function ajaxNewsletter() {
    (function ($) {
        var $form = $('#newsleter-delete'),
            $contentContainer = $('#newsleter-delete-card'),
            $loader = $('.js-ajax-loader');

        $form.on('submit', function (e) {
            e.preventDefault();
            e.stopPropagation();

            var data = $form.serialize();

            deleteNewsletterPost(data);
        });

        function deleteNewsletterPost(data) {
            $.ajax({
                type: 'POST',
                url: ajax.url,
                data: data,

                beforeSend: function () {
                    $loader.show();
                },

                success: function (response) {
                    $contentContainer.html(response).hide().fadeIn();
                    $loader.hide();
                },
            });
        }
    })(jQuery);
}
