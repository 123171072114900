import select2Handler from '../modules/select2Handler';
import Pagination from './pagination/pagination';

$ = jQuery;

export default function careerFilter() {
    class CareerFilter {
        constructor(el) {
            if (!this.set(el)) return;
            this.init();
        }

        set(el) {
            this.root = el;
            this.form = this.root.querySelector('.career-filter__form');
            this.loader = this.root.querySelector('.career-filter__loader');

            this.pagination = document.querySelector(
                '[data-career-pagination] .pagination'
            );
            this.resetButton = this.root.querySelector(
                '[data-search-jobs-reset]'
            );
            this.paginationController = null;
            this.ajaxUrl = ajax.url;
            this.responseWrapper = document.querySelector(
                '[data-response-wrapper]'
            );
            this.maxNumPages = parseInt(
                this.responseWrapper.getAttribute('data-max-pages')
            );
            this.postIn = this.responseWrapper.getAttribute('data-post-in');
            this.jobOffersSection = document.querySelector('.job-offers');

            this.selects = $(this.root).find('[data-career-select]');
            this.selectsInstances = [];

            this.currentCountry = null;
            this.currentLevel = null;
            this.currentVoid = null;

            this.perPage = 12;
            this.page = 1;

            return true;
        }

        init() {
            this.initPagination();
            this.initReset();
            this.initSelects();
            this.paginationChange();
            this.formHandler();
        }

        initReset() {
            if (!this.resetButton || !this.form) return;
            this.resetButton.addEventListener(
                'click',
                this.resetForm.bind(this)
            );
        }

        initSelects() {
            if (!this.selects) return;
            this.selectsInstances = select2Handler(this.selects);

            this.selectsInstances.forEach(item => {
                const selectType = item.attr('name');

                item.on('select2:opening select2:closing', function (event) {
                    var $searchfield = $(this)
                        .parent()
                        .find('.select2-search__field');
                    $searchfield.prop('disabled', true);
                });

                if (selectType !== 'country') {
                    item.on('select2:unselect', async () => {
                        if (selectType !== 'positions[]')
                            this.setPositionSelectOptions();

                        if (
                            selectType === 'positions[]'
                        ) {
                            if (item.val().length === 0)
                                item.removeClass('selected');
                        }
                    });

                    item.on('select2:select', async () => {
                        item.addClass('selected');
                        let label = item.siblings(
                            '.form-select__label-multiple'
                        );

                        if (selectType === 'position-level') {
                            this.currentLevel = item.val();
                            this.setPositionSelectOptions();
                        }
                        if (selectType === 'regions') {
                            this.currentVoid = item.val();
                            this.setPositionSelectOptions();
                        }

                        console.log(item.val(), label);

                        if (label.length > 0) {
                            const valstr = item.val().join(', ');
                            label.html(valstr);
                        }
                    });
                }

                if (selectType === 'country') {
                    item.on('select2:select', async () => {
                        item.addClass('selected');

                        const regionSelect = this.root.querySelector(
                            '.form-select--regions'
                        );

                        if (item.val() !== 'PL') {
                            if (regionSelect) {
                                regionSelect.classList.add('disabled');

                                const regionSelectEl =
                                    regionSelect.querySelector('select');
                                $(regionSelectEl).val([]).trigger('change');
                            }
                        } else {
                            if (regionSelect)
                                regionSelect.classList.remove('disabled');
                        }

                        this.setPositionSelectOptions();
                        this.currentCountry = item.val();
                    });
                }
            });
        }

        initPagination() {
            if (!this.pagination) return;
            this.paginationController = new Pagination(
                this.pagination,
                this.root
            );
        }

        paginationChange() {
            this.root.addEventListener('pagination-change', e => {
                this.loader.classList.add('active');
                this.getJobs(e);
            });
        }

        resetForm() {
            if (!this.form) return;
            this.form.reset();

            if (this.selectsInstances) {
                this.selectsInstances.forEach(item => {
                    item.val(null).trigger('change');
                    item.val([]).change();

                    item.removeClass('selected');
                });
            }

            this.setPositionSelectOptions();
        }

        async setPositionSelectOptions() {
            this.loader.classList.remove('d-none');
            let positions = await this.getPositionsForCountries();
            let positionSelect = this.selectsInstances.filter(
                select => select.attr('name') === 'positions[]'
            );
            this.loader.classList.add('d-none');

            if (positionSelect.length) {
                positionSelect.forEach(select => {
                    select.removeClass('selected');

                    let optionsStr = `<option></option>`;

                    positions.forEach((pos, idx) => {
                        optionsStr += `<option>${pos}</option>`;
                    });

                    select.empty();
                    select.html(optionsStr);

                    //this.initSelects();
                });
            }
        }

        formHandler() {
            if (!this.form) return;
            this.form.addEventListener('submit', e => this.searchJobs(e));
        }

        async searchJobs(e) {
            e.preventDefault();
            const data = new FormData(this.form);
            data.append('action', 'search_jobs');

            try {
                const response = await fetch(this.ajaxUrl, {
                    body: data,
                    method: 'POST',
                });
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                const responseData = await response.json();
                const { html, max_pages, post__in } = responseData;
                this.responseWrapper.innerHTML = html;
                this.maxNumPages = max_pages;
                this.page = 1;
                this.paginationController.reset(this.page, this.maxNumPages);
                this.postIn = post__in;
                this.scrollToJobs();
            } catch (error) {
                console.error(error);
            }
        }

        async getJobs(e) {
            const { detail } = e;
            this.page = parseInt(detail);
            const data = new FormData();
            data.append('action', 'get_jobs');
            data.append('page', this.page);
            data.append('post__in', this.postIn);
            try {
                const response = await fetch(this.ajaxUrl, {
                    body: data,
                    method: 'POST',
                });
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                const responseData = await response.json();
                this.responseWrapper.innerHTML = responseData.html;
                this.paginationController.reset(this.page, this.maxNumPages);
                this.loader.classList.remove('active');
                this.scrollToJobs();
            } catch (error) {
                console.error(error);
            }
        }

        async getPositionsForCountries() {
            const data = new FormData(this.form);
            data.append('action', 'get_job_positions_by_country');

            let positions = [];

            try {
                const response = await fetch(this.ajaxUrl, {
                    body: data,
                    method: 'POST',
                });
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                const responseData = await response.json();
                positions = responseData.positions;
            } catch (error) {
                console.error(error);
            }

            return positions;
        }

        scrollToJobs() {
            window.scrollTo({
                top: this.root.getBoundingClientRect().height,
                left: 0,
                behavior: 'smooth',
            });
        }
    }

    const careerFilterSection = document.querySelector('.career-filter');
    if (!careerFilterSection) return;
    new CareerFilter(careerFilterSection);
}
