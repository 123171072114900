export default class BaseFilter {
    constructor(el, parent) {
        if (!this.set(el, parent)) return;
        this.init();
    }

    set(el, parent) {
        if (!el || !parent) return false;
        this.root = el;
        this.parent = parent;

        this.toggler = this.root.getAttribute('data-section-toggler');
        this.dependant = this.root.getAttribute('data-dependant');
        this.wrapperEl = this.root.querySelector(
            '[data-filter-switcher-wrapper]'
        );
        this.currentEl = this.root.querySelector(
            '[data-filter-switcher-current]'
        );
        this.currentElIcon = this.root.querySelector(
            '[data-filter-switcher-current-icon]'
        );
        this.currentElText = this.root.querySelector(
            '[data-filter-switcher-current-text]'
        );
        this.items = this.root.querySelectorAll('[data-filter-switcher-item]');

        this.active = null;
        this.activeToggle = null;
        this.toggleItems = [];

        this.slider = this.parent.querySelector('.filter-switcher__slider');
        this.sliderWrapper = this.slider
            ? this.slider.querySelector('.-custom-slider')
            : null;
        this.isDown = null;
        this.startX = null;
        this.scrollLeft = null;
        this.initialTranslateX = 0;
        this.viewportItems = 7;
        this.activeKey = null;

        return true;
    }

    init() {
        this.initToggle();
        this.initItems();
        this.initCurrent();

        if (window.innerWidth > 991.98) {
            this.initSlider();
        } else {
            this.destroySlider();
        }

        window.addEventListener('resize', () => {
            if (window.innerWidth > 991.98) {
                this.initSlider();
            } else {
                this.destroySlider();
            }
        });
    }

    initToggle() {
        if (this.toggler === null && !this.items) return;

        this.items.forEach(item => {
            const name = item.getAttribute('data-filter-switcher-item');
            this.toggleItems.push(name);
        });
    }

    initCurrent() {
        if (!this.currentEl || !this.items) return;

        this.onCurrent = this.onCurrentClick.bind(this);
        this.currentEl.addEventListener('click', event =>
            this.onCurrent(event)
        );
    }

    initItems() {
        if (!this.items) return;

        this.onClick = this.onItemClick.bind(this);
        this.items.forEach((item, key) => {
            item.addEventListener('click', event => {
                this.activeKey = key;
                this.onClick(event);
            });
        });

        if (this.toggler !== null) this.items[0].click();
    }

    destroySlider() {}

    initSlider() {
        if (!this.slider || !this.items || !this.sliderWrapper) return;

        const sliderWrapper = this.sliderWrapper;

        sliderWrapper.addEventListener('mousedown', this.startDrag.bind(this));
        sliderWrapper.addEventListener('touchstart', this.startDrag.bind(this));

        sliderWrapper.addEventListener('mousemove', this.move.bind(this));
        sliderWrapper.addEventListener('touchmove', this.move.bind(this));

        sliderWrapper.addEventListener('mouseleave', this.startEnd.bind(this));
        sliderWrapper.addEventListener('mouseup', this.startEnd.bind(this));
        sliderWrapper.addEventListener('touchend', this.startEnd.bind(this));
    }

    startDrag(e) {
        e.preventDefault();
        this.isDown = true;
        this.sliderWrapper.classList.add('active');
        this.startX = e.pageX || e.touches[0].pageX;
        const transformStyle = window.getComputedStyle(
            this.sliderWrapper
        ).transform;
        const matrix = new DOMMatrix(transformStyle);
        this.initialTranslateX = matrix.m41;
        this.scrollLeft = -this.initialTranslateX;
    }

    move(e) {
        if (!this.isDown) return;

        e.preventDefault();
        const x = e.pageX || e.touches[0].pageX;
        const dist = x - this.startX;

        const windowWidth = window.innerWidth;

        if (windowWidth > 1299) {
            this.viewportItems = 8;
        } else if (windowWidth < 1300 && windowWidth > 1199) {
            this.viewportItems = 7;
        } else {
            this.viewportItems = 6;
        }

        if (
            -(this.scrollLeft - dist) > 0 ||
            this.viewportItems >= this.items.length
        ) {
            this.initialTranslateX = 0;
        } else {
            if (-(this.scrollLeft - dist) > -this.items.length * 100) {
                this.initialTranslateX = -(this.scrollLeft - dist);
            } else {
                this.initialTranslateX = -(this.items.length * 100) + 200;
            }
        }

        // Use translateX to move the slider horizontally
        this.sliderWrapper.style.transform = `translateX(${this.initialTranslateX}px)`;
    }

    startEnd() {
        this.isDown = false;
        this.sliderWrapper.classList.remove('active');
    }

    onItemClick(event) {
        const { currentTarget } = event;
        const value = currentTarget.getAttribute('data-filter-switcher-item');
        const toggleDependant = currentTarget.getAttribute(
            'data-filter-switcher-toggle-dependant'
        );
        const parentFilter = currentTarget.closest('[data-dependant]');

        if (window.innerWidth < 992) this.currentEl.classList.remove('opened');
        this.wrapperEl.classList.remove('opened');
        this.resetDependants();
        this.setCurrent(currentTarget);
        this.resetItems();

        if (value === this.active) {
            this.active = null;
        } else {
            currentTarget.classList.add('active');
            this.active = value;
        }

        if (this.toggler !== null) {
            let activeToggle = this.toggleItems.filter(item => item === value);
            let remainingToggles = this.toggleItems.filter(
                item => item !== value
            );
            if (activeToggle) {
                if (this.activeToggle === activeToggle[0]) {
                    return;
                } else {
                    if (activeToggle[0] === '') return;
                    this.activeToggle = activeToggle[0];
                }
            }

            const activeToggleItems = document.querySelectorAll(
                `.${activeToggle}`
            );
            activeToggleItems.forEach(item => item.classList.remove('d-none'));

            remainingToggles.forEach(item => {
                if (item !== '') {
                    const remainingTogglesItems = document.querySelectorAll(
                        `.${item}`
                    );
                    remainingTogglesItems.forEach(rem =>
                        rem.classList.add('d-none')
                    );
                }
            });
        }

        if (parentFilter) parentFilter.classList.add('active');
        if (toggleDependant !== null && toggleDependant !== '') {
            const dep = document.querySelector(
                `[data-dependant="${this.active}"]`
            );

            if (dep !== null) {
                this.setActiveDependant(dep);
                if (dep.querySelectorAll('.filter-switcher__item').length > 0) {
                    document.querySelector(`[data-filter-switcher-item="${toggleDependant}"]`).classList.add('dependant');
                }

                const depButton = dep.querySelector('[data-filter-switcher-item]');
                const depValue = depButton.getAttribute('data-filter-switcher-item');

                this.active = parseInt(depValue);
                depButton.classList.add('active');
                this.setCurrentDependant(dep, depButton);
                this.sendEvent(depButton);
            } else {
                this.sendEvent(currentTarget);
            }
        } else {
            this.sendEvent(currentTarget);
        }
    }

    onCurrentClick(event) {
        const { currentTarget } = event;
        currentTarget.classList.toggle('opened');
        this.wrapperEl.classList.toggle('opened');
    }

    resetItems() {
        if (!this.items) return;
        this.items.forEach(item => {
            item.classList.remove('active');
        });
    }

    setCurrent(item) {
        const icon = item.querySelector('[data-filter-switcher-item-icon]');
        const name = item.querySelector('[data-filter-switcher-item-name]');

        const iconSrc = icon ? icon.src : '';
        const nameText = name ? name.innerHTML : '';

        if (this.currentElIcon) {
            this.currentElIcon.src = iconSrc;

            if (icon === null || icon === undefined) {
                this.currentElIcon.parentNode.style.display = 'none';
            } else {
                this.currentElIcon.parentNode.style.display = 'flex';
            }
        }
        if (this.currentElText) this.currentElText.innerHTML = nameText;
    }

    setCurrentDependant(dependant, item) {
        const dependantElText = dependant.querySelector('[data-filter-switcher-current-text]');
        const name = item.querySelector('[data-filter-switcher-item-name]');
        const nameText = name ? name.innerHTML : '';

        if (dependantElText) dependantElText.innerHTML = nameText;
    }

    resetDependants() {
        const filterNextSibling = this.root.nextElementSibling;
        if (!filterNextSibling) return;

        const dependant = filterNextSibling.querySelectorAll('[data-dependant]');
        if (!dependant) return;
        dependant.forEach(item => {
            const buttons = item.querySelectorAll(
                '[data-filter-switcher-item]'
            );
            item.classList.remove('active');

            buttons.forEach(button => button.classList.remove('active'));
        });
    }

    setActiveDependant(filter) {
        filter.classList.add('active');
    }

    sendEvent(item) {
        const customEv = new CustomEvent('filter-switcher-change', {
            detail: {
                el: this.root,
                target: item,
                value: this.active,
                settings: this.items[this.activeKey].getAttribute(
                    'data-filter-switcher-settings'
                ),
            },
        });

        this.parent.dispatchEvent(customEv);
    }

    toggleClick(index) {
        const item = this.items[index].click();
    }
}
