import PopupVideo from '../modules/popupVideo';
$ = jQuery;

class MegamenuVideo {
    constructor(el) {
        if (!this.set(el)) return;
        this.init();
    }

    set(el) {
        if (!el) return false;

        this.root = el;
        this.url = this.root.getAttribute('data-megamenu-video');

        return true;
    }

    init() {
        this.setCard();
    }
    
    setCard() {
        if (!this.root) return;
        this.root.addEventListener('click', event => this.onMediaCard(event));
    }

    onMediaCard(event) {
        event.preventDefault();

        this.root.style.pointerEvents = 'none';

        const formData = new FormData();
        formData.append('action', 'media_popup');
        formData.append('type', 'wideo');
        formData.append('id', this.url);

        $.ajax({
            type: 'POST',
            url: ajax.url,
            enctype: 'multipart/form-data',
            processData: false,
            contentType: false,
            data: formData,
            success: function (response) {
                const data = JSON.parse(response);
                const { html } = data;

                $('body').append(html);
                this.setPopup();
            }.bind(this),
            error: function () {}.bind(this),
            complete: function () {
                this.root.style.pointerEvents = 'all';
            }.bind(this),
        });
    }

    setPopup() {
        const popup = document.querySelector('[data-popup]');
        if (popup) new PopupVideo(popup);
    }
}

export default function initMegamenuVideo() {
    const sections = document.querySelectorAll('[data-megamenu-video]');
    if (!sections) return;

    sections.forEach(el => {
        new MegamenuVideo(el);
    });
};