import debounce from 'lodash.debounce';

class ContactInfoFilter {
    constructor(el) {
        if (!this.set(el)) return;
        this.init();
    }

    set(el) {
        if (!el) return false;

        this.root = el;
        this.selects = $(this.root).find('[data-contact-filter-select]');
        this.listWrapper = $(this.root).find('[data-contact-filter-list]');
        this.loader = $(this.root).find('[data-contact-filter-loader]');

        this.settings = JSON.parse(this.root.getAttribute('data-settings'));
        this.loadContacts = debounce(this.filterContacts, 1000);

        return true;
    }

    init() {
        this.initSelects();
    }

    initSelects() {
        if (!this.selects) return;

        const instance = this;
        this.selects.each(function () {
            const select = $(this);
            const settings = JSON.parse(select.attr('data-settings'));
            const { first_disabled, placeholder } = settings;

            const selectOptions = {
                minimumResultsForSearch: -1,
                allowClear: true,
            };

            if (first_disabled) selectOptions.placeholder = placeholder;

            select.select2(selectOptions);
            select.on('select2:select', function (e) {
                instance.onSelect(e);
            });

            select.on('select2:clear', function (e) {
                instance.onSelect(e);
            });
        });
    }

    onSelect(event) {
        this.loader.removeClass('d-none');
        this.loadContacts();
    }

    filterContacts() {
        const { cities, departments, regions, group_by } = this.settings;

        const formData = new FormData();
        formData.append('action', 'contact_groups');
        formData.append('group_by', group_by);
        if (cities) formData.append('cities', cities);
        if (departments) formData.append('departments', departments);
        if (regions) formData.append('regions', regions);

        this.selects.each(function () {
            const select = $(this);
            const value = select.val();
            const name = select.attr('data-contact-filter-select');

            if (value !== '') {
                const valueArr = [parseInt(value)];
                formData.append(name, valueArr);
            }
        });

        jQuery.ajax({
            type: 'POST',
            url: ajax.url,
            enctype: 'multipart/form-data',
            processData: false,
            contentType: false,
            data: formData,
            success: function (response) {
                const data = JSON.parse(response);
                const { html } = data;

                this.listWrapper.html(html);
            }.bind(this),
            error: function () {}.bind(this),
            complete: function () {
                this.loader.addClass('d-none');
            }.bind(this),
        });
    }
}

export default function contactInfoFilters() {
    const sections = document.querySelectorAll('[data-contact-filter]');
    sections.forEach(item => new ContactInfoFilter(item));
}
