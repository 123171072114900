class FileInput {
    constructor(el) {
        if (!this.set(el)) return;
        this.init();
    }

    set(el) {
        if (!el) return false;

        this.root = el;
        this.input = this.root.querySelector('[data-file-input]');
        this.button = this.root.querySelector('[data-file-button]');
        this.uploadStatusEl = this.root.querySelector(
            '[data-file-upload-status]'
        );

        this.emptyMessageText = this.root.querySelector(
            '[data-file-empty-message]'
        ).value;

        return true;
    }

    init() {
        this.bindButton();
        this.bindInput();
    }

    bindButton() {
        if (!this.button) return;
        this.button.addEventListener('click', this.onButton.bind(this));
    }

    bindInput() {
        if (!this.input) return;
        this.input.addEventListener('change', event => this.onFileInput(event));
    }

    onButton(event) {
        this.input.click();
    }

    onFileInput(event) {
        const { target } = event;
        const { files } = target;

        let fileMessage = '';
        const filesArr = Array.from(files);
        const fileNames = filesArr.map(item => item.name);

        if (fileNames.length > 0) fileMessage = fileNames.join(',');
        this.uploadStatusEl.innerHTML =
            fileMessage !== '' ? fileMessage : this.emptyMessageText;
    }
}

export default function files() {
    const files = document.querySelectorAll('[data-file]');
    if (files) {
        files.forEach(item => new FileInput(item));
    }
}
