import Pagination from './pagination/pagination';

export default function search() {
    const searchSection = $('.search')[0];
    if (!searchSection) return;

    const titleEl = $(searchSection).find('.search__title');
    const pagination = $(searchSection).find('[data-pagination]')[0];
    const paginationController = new Pagination(pagination, searchSection);
    const postsWrapper = $(searchSection).find('.search__posts-list .row');
    const searchPhrase = $(searchSection).find('.js_header_search_input').val();
    const loaderElement = $(searchSection).find('.search__loader');
    const maxPages = $(searchSection).find('[data-maxpages]').data('maxpages');

    let currentPage = 1;

    const getSearchItems = () => {
        const formData = new FormData();
        formData.append('action', 'news_list');
        formData.append('per_page', '6');
        formData.append('current_page', currentPage);
        formData.append('is_search', true);
        formData.append('search_phrase', searchPhrase);

        jQuery.ajax({
            type: 'POST',
            url: ajax.url,
            enctype: 'multipart/form-data',
            processData: false,
            contentType: false,
            data: formData,
            success: function (response) {
                const data = JSON.parse(response);
                const { maxNumPages, paged, html } = data;

                postsWrapper.html(html);
                paginationController.reset(paged, parseInt(maxPages));
                loaderElement.addClass('hide');
            },
            complete: function () {
                if (titleEl)
                    titleEl.get(0).scrollIntoView({ behavior: 'smooth' });
            },
        });
    };

    searchSection.addEventListener('pagination-change', event => {
        const { detail } = event;

        currentPage = detail;
        refreshList();
    });

    const refreshList = () => {
        loaderElement.removeClass('hide');
        getSearchItems();
    };
}
