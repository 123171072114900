class SendNewsletter {
    constructor(el) {
        if (!this.set(el)) return;
        this.init();
    }

    set(el) {
        if (!el) return false;

        this.root = el;
        this.buttonEl = this.root.querySelector('[data-send-newsletter-button]');
        this.statusEl = this.root.querySelector('[data-send-newsletter-status]');

        return true;
    }

    init() {
        this.setButton();   
    }

    setButton() {
        if (!this.buttonEl) return;
        this.buttonEl.addEventListener('click', this.onButtonClick.bind(this));
    }

    onButtonClick(event) {
        const { currentTarget } = event;
        const id = currentTarget.getAttribute('data-send-newsletter-button');
        this.sendNewsletter(id);
    }
    
    sendNewsletter(id) {
        const formData = new FormData();
        formData.append('action', 'button_send_newsletter');
        formData.append('id', id);

        jQuery.ajax({
            type: 'POST',
            url: ajax.url,
            enctype: 'multipart/form-data',
            processData: false,
            contentType: false,
            data: formData,
            success: function (response) {
                const data = JSON.parse(response);
                const { status } = data;

                if (this.statusEl) this.statusEl.innerHTML = status;
            }.bind(this),
            error: function () {}.bind(this),
            complete: function () {}.bind(this),
        });
    }
}

export default function setSendNewsletter() {
    const buttons = document.querySelectorAll('[data-send-newsletter]');
    if (!buttons) return;

    buttons.forEach(item => {
        new SendNewsletter(item);
    });
}