export default function cardSimpleImage() {
    const removePopup = () => {
        const popup = document.querySelector('.popup');
        popup.remove();
    };

    const onMediaCart = async event => {
        const { currentTarget } = event;
        const type = currentTarget.getAttribute('data-type');
        const id = currentTarget.getAttribute('data-card-media');

        const formData = new FormData();
        formData.append('action', 'media_popup');
        formData.append('type', type);
        formData.append('id', id);

        try {
            const response = await fetch(ajax.url, {
                body: formData,
                method: 'POST',
            });
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const responseData = await response.json();
            const { html } = responseData;
            let frag = document.createRange().createContextualFragment(html);
            document.body.appendChild(frag);

            document
                .querySelector('.popup__overlay')
                .addEventListener('click', removePopup);
            document
                .querySelector('.popup-photo__close')
                .addEventListener('click', removePopup);
        } catch (error) {
            console.error(error);
        }
    };

    const initCards = () => {
        const cards = document.querySelectorAll('.card-simple-image');
        if (!cards) return;

        cards.forEach(card => {
            const dataContent = card.getAttribute('data-card-media');
            if (dataContent) {
                card.addEventListener('click', event => onMediaCart(event));
            }
        });
    };

    initCards();

    document.addEventListener('on-load-news', () => {
        initCards();
    });
}
