import Swiper, { Navigation, Pagination } from 'swiper';

export default class postFeaturedSection {
    constructor(el) {
        if (!this.set(el)) return;
        this.init();
    }

    set(el) {
        if (!el) return false;
        this.root = el;
        this.slider = this.root.querySelector('[data-post-featured-swiper]');
        this.pagination = this.root.querySelector(
            '[data-post-featured-swiper-pagination]'
        );
        this.sliderInstance = null;

        Swiper.use([Pagination, Navigation]);

        return true;
    }

    init() {
        this.initSlider();
    }

    initSlider() {
        if (!this.slider) return;
        this.sliderInstance = new Swiper(this.slider, {
            breakpoints: {
                0: {
                    spaceBetween: 24,
                    slidesPerView: 'auto',
                },
                991.98: {
                    spaceBetween: 0,
                    slidesPerView: 3,
                },
            },
            slideActiveClass: 'is-active',
            pagination: {
                el: this.pagination,
                type: 'bullets',
                bulletClass: 'bottom-pagination__bullet',
                bulletActiveClass: 'active',
            },
            navigation: {
                nextEl: `.post-featured__arrow-desktop--next`,
                prevEl: `.post-featured__arrow-desktop--prev`,
            },
        });
    }
}
