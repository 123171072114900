export default class Pagination {
    constructor(el, parent) {
        if (!this.set(el, parent)) return;
        this.init();
    }

    set(el, parent) {
        if (!el || !parent) return false;

        this.root = el;
        this.parent = parent;
        this.settings = JSON.parse(el.getAttribute('data-settings'));
        this.numbers = this.root.querySelector('.pagination__numbers');
        this.prev = this.root.querySelector('.pagination__nav--prev');
        this.next = this.root.querySelector('.pagination__nav--next');
        this.currentPage = 1;
        this.maxPages = this.settings.max_pages;

        return true;
    }

    init() {
        this.setItemsTemplate();
        this.setActiveItem();
        this.initNumbers();
        this.bindNav();
        this.setArrows();
    }

    initNumbers() {
        if (!this.items) return;
        this.items.forEach(item => {
            item.addEventListener('click', event => this.onChange(event));
        });
    }

    bindNav() {
        if (!this.prev || !this.next) return;
        this.onPrevHandler = this.onPrev.bind(this);
        this.onNextHandler = this.onNext.bind(this);
        this.onFirstHandler = this.onFirst.bind(this);
        this.onLastHandler = this.onLast.bind(this);

        this.prev.addEventListener('click', this.onPrevHandler);
        this.next.addEventListener('click', this.onNextHandler);
    }

    onChange(event) {
        const { currentTarget } = event;
        const index = currentTarget.getAttribute('data-item');
        this.currentPage = index;
        this.sendChangeEvent(index);
        this.setActiveItem();
        this.setArrows();
    }

    onFirst() {
        this.currentPage = 1;
        this.sendChangeEvent(this.currentPage);
        this.setActiveItem();
        this.setArrows();
    }

    onLast() {
        this.currentPage = this.maxPages;
        this.sendChangeEvent(this.currentPage);
        this.setActiveItem();
        this.setArrows();
    }

    onPrev() {
        let current = this.currentPage;
        current = current - 1;
        if (current < 1) current = 1;
        this.currentPage = current;
        this.setArrows();
        this.sendChangeEvent(this.currentPage);
        this.setActiveItem();
    }

    onNext() {
        let current = parseInt(this.currentPage);
        current = current + 1;
        if (current > this.maxPages) current = this.maxPages;
        this.currentPage = current;
        this.setArrows();
        this.sendChangeEvent(this.currentPage);
        this.setActiveItem();
    }

    sendChangeEvent(index) {
        const event = new CustomEvent('pagination-change', {
            detail: parseInt(index),
        });

        this.parent.dispatchEvent(event);
    }

    setActiveItem() {
        this.resetItems();
        const active = this.root.querySelector(
            `[data-item="${this.currentPage}"]`
        );
        if (!active) return;

        active.classList.add('active');
    }

    resetItems() {
        if (!this.items) return;
        this.items.forEach(item => {
            item.classList.remove('active');
        });
    }

    reset(currentPage, maxPages) {
        this.currentPage = parseInt(currentPage);
        this.maxPages = parseInt(maxPages);
        this.clearListeners();
        this.init();
    }

    setItemsTemplate() {
        if (this.maxPages === 1 || this.maxPages === 0) {
            this.root.style.display = 'none';
            return;
        }

        const pageRange = this.pageRange(this.currentPage, this.maxPages);

        const { start, end, showLastPage, showFirstPage } = pageRange;

        this.root.style.display = '';
        let template = ``;

        if (showFirstPage) {
            template += `<button class="pagination__item" data-item="1">1</button>`;
            if (this.currentPage > 2) template += `<button class="pagination__item -disabled" data-item="...">...</button>`;
        }

        for (let i = start; i <= end; i++) {
            template += `<button class="pagination__item" data-item="${i}">${i}</button>`;
        }

        if (showLastPage) {
            template += `<button class="pagination__item -disabled" data-item="...">...</button><button class="pagination__item" data-item="${this.maxPages}">${this.maxPages}</button>`;
        }

        this.numbers.innerHTML = template;
        this.items = this.root.querySelectorAll('.pagination__item');
    }

    pageRange(page, pageCount) {
        let start = page,
            end = page + 1;

        if (end > pageCount) {
            start -= end - pageCount;
            end = pageCount;
        }
        if (start <= 0) {
            end += (start - 1) * -1;
            start = 1;
        }

        end = end > pageCount ? pageCount : end;

        const rangeObject = { start: start, end: end };

        if (this.maxPages > end ) {
            rangeObject['showLastPage'] = true;
        }

        if (start > 1) {
            rangeObject['showFirstPage'] = true;
        }

        return rangeObject;
    }

    clearListeners() {
        this.prev.removeEventListener('click', this.onPrevHandler);
        this.next.removeEventListener('click', this.onNextHandler);

        if (this.items) {
            this.items.forEach(item => {
                item.removeEventListener('click', this.onChange);
            });
        }
    }

    hidePagination() {
        this.root.style.display = 'none';
    }

    showPagination() {
        this.root.style.display = '';
    }

    setArrows() {
        if (this.currentPage === 1) {
            this.prev.classList.add('d-none');
        } else if (this.currentPage === this.maxPages) {
            this.next.classList.add('d-none');
        } else {
            this.prev.classList.remove('d-none');
            this.next.classList.remove('d-none');
        }
    }
}
