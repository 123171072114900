import 'jquery-ui/ui/widgets/datepicker';
$ = jQuery;

export default class FormDatepicker {
    constructor(el) {
        if (!this.set(el)) return;
        this.init();
    }

    set(el) {
        if (!el) return false;

        this.root = el;
        this.icon = this.root.querySelector('.form-datepicker__icon');

        this.instance = null;

        return true;
    }

    init() {
        this.setPicker();
    }

    setPicker() {
        $.datepicker.regional['pl'] = {
            closeText: 'Zamknij',
            prevText: 'Poprzedni',
            nextText: 'Następny',
            currentText: 'Dziś',
            monthNames: [
                'Styczeń',
                'Luty',
                'Marzec',
                'Kwiecień',
                'Maj',
                'Czerwiec',
                'Lipiec',
                'Sierpień',
                'Wrzesień',
                'Październik',
                'Listopad',
                'Grudzień',
            ],
            monthNamesShort: [
                'Styczeń',
                'Luty',
                'Marzec',
                'Kwiecień',
                'Maj',
                'Czerwiec',
                'Lipiec',
                'Sierpień',
                'Wrzesień',
                'Październik',
                'Listopad',
                'Grudzień',
            ],
            dayNames: [
                'Niedziela',
                'Poniedziałek',
                'Wtorek',
                'Środa',
                'Czwartek',
                'Piątek',
                'Sobota',
            ],
            dayNamesShort: ['Nie', 'Pn', 'Wt', 'Śr', 'Czw', 'Pt', 'So'],
            dayNamesMin: ['N', 'Pn', 'Wt', 'Śr', 'Cz', 'Pt', 'So'],
            weekHeader: 'Tydz',
            dateFormat: 'dd.mm.yy',
            firstDay: 1,
            isRTL: false,
            changeYear: true,
            changeMonth: true,
            showMonthAfterYear: false,
            yearSuffix: '',
        };
        $.datepicker.regional['en'] = {
            closeText: 'Close',
            prevText: 'Previous',
            nextText: 'Next',
            currentText: 'Today',
            monthNames: [
                'January',
                'February',
                'March',
                'April',
                'May',
                'June',
                'July',
                'August',
                'September',
                'October',
                'November',
                'December',
            ],
            monthNamesShort: [
                'January',
                'February',
                'March',
                'April',
                'May',
                'June',
                'July',
                'August',
                'September',
                'October',
                'November',
                'December',
            ],
            dayNames: [
                'Sunday',
                'Monday',
                'Tuesday',
                'Wednesday',
                'Thursday',
                'Friday',
                'Saturday',
            ],
            dayNamesShort: ['Sun', 'Mon', 'Tu', 'Wed', 'Th', 'Fri', 'Sat'],
            dayNamesMin: ['Sun', 'Mon', 'Tu', 'Wed', 'Th', 'Fri', 'Sat'],
            weekHeader: 'Week',
            dateFormat: 'dd.mm.yy',
            firstDay: 1,
            isRTL: false,
            changeYear: true,
            changeMonth: true,
            showMonthAfterYear: false,
            yearSuffix: '',
        };

        if (!/[?&]lang=/.test(location.search)) {
            if (document.documentElement.lang.includes("pl")) {
                $.datepicker.setDefaults($.datepicker.regional.pl);
            } else {
                $.datepicker.setDefaults($.datepicker.regional.en);
            }
        }

        this.dateToday = null;
        let initDate = this.root.getAttribute('data-init-date');
        if (initDate) {
            this.dateToday = new Date(initDate);
        }
        this.instance = $(this.root).datepicker({
            dateFormat: 'dd.mm.yy',
            autoUpdateInput: false,
            onClose: date => {
                //$(this.icon).removeClass('input-datepicker__icon--rotated');
                //$(this.icon).removeClass('input-datepicker__input--active');
            },
            onSelect: date => {
                const event = new CustomEvent('datepicker-change', {
                    detail: this.root.value,
                });
                this.root.dispatchEvent(event);
            },
            beforeShow: (input, inst) => {
                setTimeout(function () {
                    inst.dpDiv.outerWidth($(input).outerWidth());
                }, 0);
            },
            minDate: this.dateToday,
            maxDate: "0",
        });
    }
}
