import {
    BarController,
    BarElement,
    CategoryScale,
    Chart,
    LinearScale,
    LineController,
    Tooltip,
} from 'chart.js';

Chart.register(
    BarElement,
    BarController,
    LineController,
    CategoryScale,
    LinearScale,
    Tooltip,
    ChartDataLabels
);

import ChartDataLabels from 'chartjs-plugin-datalabels';

var $chartAnalysts = $('#analysts_chart');
var $data = $chartAnalysts.data('content');
var $labels = $chartAnalysts.data('labels');

if ($labels) {
    var $split_labels = $labels.split(',');
}

if ($data) {
    const getOrCreateTooltip = chart => {
        let tooltipEl = chart.canvas.parentNode.querySelector('div');

        if (!tooltipEl) {
            tooltipEl = document.createElement('div');
            tooltipEl.classList.add('chart-tooltip');

            const table = document.createElement('table');
            table.classList.add('chart-table');

            tooltipEl.appendChild(table);
            chart.canvas.parentNode.appendChild(tooltipEl);
        }

        return tooltipEl;
    };

    const externalTooltipHandler = context => {
        // Tooltip Element
        const { chart, tooltip } = context;
        const tooltipEl = getOrCreateTooltip(chart);

        // Hide if no tooltip
        if (tooltip.opacity === 0) {
            tooltipEl.style.opacity = 0;
            return;
        }

        // Set Text
        if (tooltip.body) {
            const titleLines = tooltip.title || [];
            const bodyLines = tooltip.body.map(b => b.lines);

            const tableHead = document.createElement('thead');

            titleLines.forEach(title => {
                const tr = document.createElement('tr');
                tr.style.borderWidth = 0;

                const th = document.createElement('th');
                th.style.borderWidth = 0;
                const text = document.createTextNode(title);

                th.appendChild(text);
                tr.appendChild(th);
                tableHead.appendChild(tr);
            });

            const tableBody = document.createElement('tbody');
            bodyLines.forEach((body, i) => {
                const colors = tooltip.labelColors[i];

                const span = document.createElement('span');
                span.style.background = colors.backgroundColor;
                span.style.borderColor = colors.borderColor;
                span.style.borderWidth = '2px';
                span.style.marginRight = '10px';
                span.style.height = '10px';
                span.style.width = '10px';
                span.style.display = 'inline-block';

                const tr = document.createElement('tr');
                tr.style.backgroundColor = 'inherit';
                tr.style.borderWidth = 0;

                const td = document.createElement('td');
                td.style.borderWidth = 0;

                const text = document.createTextNode(body);

                td.appendChild(span);
                td.appendChild(text);
                tr.appendChild(td);
                tableBody.appendChild(tr);
            });

            const tableRoot = tooltipEl.querySelector('table');

            // Remove old children
            while (tableRoot.firstChild) {
                tableRoot.firstChild.remove();
            }

            // Add new children
            tableRoot.appendChild(tableHead);
            tableRoot.appendChild(tableBody);
        }

        const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

        // Display, position, and set styles for font
        tooltipEl.style.opacity = 1;
        tooltipEl.style.left = positionX + tooltip.caretX + 'px';
        tooltipEl.style.top = positionY + tooltip.caretY + 'px';
        tooltipEl.style.font = tooltip.options.bodyFont.string;
        tooltipEl.style.padding =
            tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
    };

    const myChart = new Chart($chartAnalysts, {
        type: 'bar',
        data: {
            labels: $split_labels,
            datasets: [
                {
                    label: $chartAnalysts[0].getAttribute('data-brokers-office'),
                    data: $data,
                    backgroundColor: ['#EBB700'],
                },
            ],
        },
        options: {
            responsive: true,
            maintainAspectRatio: false,
            layout: {
                padding: {
                    top: 100,
                },
            },
            scales: {
                y: {
                    beginAtZero: true,
                    ticks: {
                        callback: function (value, index, values) {
                            if (value == 0) {
                                return 0;
                            } else {
                                return value + ' PLN';
                            }
                        },
                    },
                },
                x: {
                    grid: {
                        display: false,
                    },
                },
            },
            barPercentage: 0.8,
            ticks: {
                stepSize: 50,
            },
            elements: {},
            borderColor: 'rgba(12,41,15,0.3',
            plugins: {
                datalabels: {
                    anchor: 'end',
                    align: 'bottom',
                    color: 'white',
                    formatter: Math.round,
                    font: {
                        size: 19,
                    },
                },
                legend: {
                    display: false,
                },
                tooltip: {
                    enabled: false,
                    position: 'nearest',
                    external: externalTooltipHandler,
                },
            },
        },
    });
}
