import Swiper, { Autoplay } from 'swiper';

export default function headerTopbar() {
    Swiper.use([Autoplay]);
    let init = false;
    let topbarStockCarousel;
    const slider = '.header-stock';

    const initSwiper = () => {
        if (window.innerWidth <= 1299.98) {
            if (!init) {
                init = true;
                $('.header-stock__item').addClass('swiper-slide');
                topbarStockCarousel = new Swiper(slider, {
                    slidesPerView: 'auto',
                    loop: true,
                    loopFillGroupWithBlank: true,
                    centeredSlides: true,
                    autoplay: {
                        delay: 0,
                        disableOnInteraction: false,
                    },
                    direction: 'horizontal',
                    centeredSlides: true,
                    speed: 2000,
                    breakpoints: {
                        0: {
                            allowTouchMove: false,
                        },
                        1300: {
                            allowTouchMove: true,
                        }
                    }
                });

                const links = document.querySelectorAll('.header-stock__link')
                links.forEach((link) => {
                    link.addEventListener('click', event => {
                        event.stopPropagation();
                    });
                });
            }
        } else if (init) {
            $('.header-stock__item').removeClass('swiper-slide');
            $('.header-stock__item.swiper-slide-duplicate').remove();
            topbarStockCarousel.forEach(element => {
                element.destroy();
            });
            init = false;
        }
    };

    initSwiper();
    window.addEventListener('resize', initSwiper);
}
