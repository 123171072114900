export default function footer() {
    $('.footer-top-menu__item.menu-item-has-children').on('click', e => {
        const submenu = $(e.target).find('.footer-top-menu__submenu');
        const arrow = $(e.target).find('.footer-top-menu__arrow');

        if (submenu.hasClass('active')) {
            submenu.removeClass('active');
            arrow.removeClass('active');
            return;
        } else {
            $(
                '.language-switcher--footer .language-switcher__lang-list'
            ).removeClass('active');
            $('.language-switcher--footer .icon').removeClass('active');
            $('.footer-top-menu__submenu').removeClass('active');
            $('.footer-top-menu__arrow').removeClass('active');
            submenu.addClass('active');
            arrow.addClass('active');
        }
    });
}
