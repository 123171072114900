const handleCardsHeight = (cards, titleHeight, investorDetailHeight, realizationDetailHeight) => {

    cards.forEach(card => {
        const title = card.querySelector(".card-offer__content");
        const investorDetail = card.querySelector(".card-offer__detail.-investor");
        const realizationDetail = card.querySelector(".card-offer__detail.-realization");

        if (title) title.style.height = `${titleHeight !== 0 ? titleHeight + "px" : "auto"}`;
        if (investorDetail) investorDetail.style.height = `${investorDetailHeight !== 0 ? investorDetailHeight + "px" : "auto"}`;
        if (realizationDetail) realizationDetail.style.height = `${realizationDetailHeight !== 0 ? realizationDetailHeight + "px" : "auto"}`;
    });
}

export const handleCardOffersHeight = (section) => {
    if (!section) return;

    const cardOffers = section.querySelectorAll(".card-offer");

    if (cardOffers.length === 0) return;

    let titleHeight = 0;
    let investorDetailHeight = 0;
    let realizationDetailHeight = 0;

    handleCardsHeight(cardOffers, titleHeight, investorDetailHeight, realizationDetailHeight);

    cardOffers.forEach(card => {
        const classList = Array.from(card.classList);
        const title = card.querySelector(".card-offer__content");
        const investorDetail = card.querySelector(".card-offer__detail.-investor");
        const realizationDetail = card.querySelector(".card-offer__detail.-realization");

        if (!classList.includes('post-news') && !classList.includes('post-press')) {
            if (title && title.offsetHeight > titleHeight) titleHeight = title.offsetHeight;
            if (investorDetail && investorDetail.offsetHeight > investorDetailHeight) investorDetailHeight = investorDetail.offsetHeight;
            if (realizationDetail && realizationDetail.offsetHeight > realizationDetailHeight) realizationDetailHeight = realizationDetail.offsetHeight;
        }
    });

    handleCardsHeight(cardOffers, titleHeight, investorDetailHeight, realizationDetailHeight);
}