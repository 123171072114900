export default function wcagPanel() {
    const wcagPanelItem = $('.js-wcag-panel-item');
    const localStorageName = 'wcagFunctions';
    const htmlElement = $('html');

    wcagPanelItem.on('click', e => {
        const clickedWcagPanel = $(e.currentTarget);
        if (!htmlElement.hasClass(`wcag-${clickedWcagPanel.data('wcag')}`)) {
            addToLocalStorage(clickedWcagPanel);
        } else {
            removeFromLocalStorage(clickedWcagPanel);
        }
    });

    const getLocalStorage = () => {
        return localStorage.getItem(localStorageName);
    };

    const initLocalStorageClasses = () => {
        const localStorageArray = JSON.parse(getLocalStorage());

        if (localStorageArray) {
            $.each(localStorageArray, (index, value) => {
                htmlElement.addClass(`wcag-${value}`);
            });
        }
    };

    initLocalStorageClasses();

    const addToLocalStorage = item => {
        if (!getLocalStorage()) {
            localStorage.setItem(localStorageName, JSON.stringify([]));
        }
        const localStorageArray = JSON.parse(getLocalStorage());
        localStorageArray.push(item.data('wcag'));
        htmlElement.addClass(`wcag-${item.data('wcag')}`);
        localStorage.setItem(
            localStorageName,
            JSON.stringify(localStorageArray)
        );
    };

    const removeFromLocalStorage = item => {
        const localStorageArray = JSON.parse(
            localStorage.getItem(localStorageName)
        );
        const removedElementLocalStorageArray = localStorageArray.filter(
            i => i !== item.data('wcag')
        );
        localStorage.setItem(
            localStorageName,
            JSON.stringify(removedElementLocalStorageArray)
        );
        htmlElement.removeClass(`wcag-${item.data('wcag')}`);
    };
}
