export default function header() {
    let isHovered = false;
    let isOpenedMobile = false;

    $(document).bind('scroll ready', () => {
        if (window.scrollY > 80) {
            $('.header').addClass('is-scrolling');
            $('.js_header_search_input').addClass('is-scrolling');
            $('.header-menu').addClass('is-scrolling');
            activeHeader();
        } else {
            $('.header').removeClass('is-scrolling');
            $('.header-menu').removeClass('is-scrolling');
            $('.js_header_search_input').removeClass('is-scrolling');
            if (!$('.header-search__form-wrapper').hasClass('active')) {
                unActiveHeader();
            }
        }
    });

    const activeHeader = () => {
        $('.header').addClass('active-desktop');
        $('.header-burger').addClass('is-scrolling');
        $('.header-menu').addClass('active');
        $('.header-brand__logo').addClass('d-none');
        $('.header-brand__wrapper').addClass('show');
        $('.header-search').addClass('active');
        $('header .wcag-panel').addClass('mobile-active');
    };

    const unActiveHeader = () => {
        if (!isHovered) {
            $('.header').removeClass('active-desktop');
            $('.header-burger').removeClass('is-scrolling');
            $('.header-brand__wrapper').removeClass('show');
            $('.header-brand__logo').removeClass('d-none');
            $('.header-menu__megamenu').removeClass('active');
            $('header .wcag-panel').removeClass('mobile-active');
            $('.header-search__form-wrapper').removeClass('active');
            $('.js-trigger-search').removeClass('d-none');
            $('.header-menu').removeClass('-hide');

            if (!$('.header-menu').hasClass('is-scrolling')) {
                $('.header-menu').removeClass('active');
                $('.header-search').removeClass('active');
            }
        }
    };

    $('.header').on('mouseover', e => {
        if (window.innerWidth > 1379) {
            if (!$('.header').hasClass('is-scrolling') && !isHovered) {
                isHovered = true;
                activeHeader();
            }
        }
    });

    $('.header-menu__item--depth-0').on('mouseover', e => {
        if (window.innerWidth > 1379) {
            if (!$('.header').hasClass('is-scrolling') && !isHovered) {
                isHovered = true;
                activeHeader();
            }
        }
    });

    $('.js-close-search').on('click', () => (isHovered = false));

    // $('.header-menu__megamenu-item').on('mouseover', e => {
    //     $('.header-menu__link--depth-1-many').removeClass('hover');
    // });

    $('.header-menu__megamenu-item--many').on('click', e => {
        $('.header-menu__megamenu-item--many').removeClass('js-active');
        if (
            e.currentTarget.classList.contains(
                'header-menu__megamenu-item--many'
            )
        ) {
            e.currentTarget.classList.add('js-active');
        }
    });

    const handleDepth0ItemClick = parentElement => {
        if (
            $(parentElement).hasClass('header-menu__item--has-children') &&
            $(parentElement).find('.header-menu__megamenu-item--many').length >
                0
        ) {
            $('.header-menu__megamenu-item--many').removeClass('js-active');
            $(parentElement)
                .find('.header-menu__megamenu-item--many')
                .first()
                .addClass('js-active');
        }
    };

    $('.header-menu__item--has-children').on('click', e => {
        handleDepth0ItemClick(e.target);
    });

    $('.header-menu__link--depth-0').on('click', e => {
        const parent = e.target.parentElement;
        handleDepth0ItemClick(parent);
    });

    // $('.header-menu__item--has-children').on('mouseover', e => {
    //     if ($(e.target).hasClass('header-menu__item--has-children')) {
    //         $(e.target)
    //             .find('.header-menu__link--depth-1-many')
    //             .first()
    //             .addClass('hover');
    //     }
    // });

    $('.header').on('mouseleave', () => {
        if (window.innerWidth > 1379) {
            const activeSubmenu = $('.header-menu__item--has-children.active');

            if (
                !$('.header').hasClass('is-scrolling') &&
                !activeSubmenu.length &&
                !$('.header-search__form-wrapper').hasClass('active')
            ) {
                isHovered = false;
                unActiveHeader();
            }
        }
    });

    // mobile

    $('#js-burger').on('click', () => {
        if ($('.header-search__form-wrapper').hasClass('active')) {
            $('.header-search__form-wrapper').removeClass('active');
            $('.header__right-wrapper').removeClass('-search-opened');
        } else {
            $('.header-menu').toggleClass('open');
            $('.header').toggleClass('mobile-opened');
            $('.header-burger').toggleClass('active');
            $('html').toggleClass('no-scroll');

            if (!$('.header').hasClass('is-scrolling')) {
                $('.header-brand__logo').toggleClass('d-none');
                $('.header-brand__wrapper').toggleClass('show');
                $('.header-search').toggleClass('active');
                $('header .wcag-panel').toggleClass('mobile-active');
            }
            $('.header-menu__megamenu').removeClass('active');
            $('.header-menu__items-depth-2-wrapper--many').removeClass(
                'active'
            );
        }
        if ($('.header-burger').hasClass('active')) {
            $('.header-topbar').addClass('active');
        } else {
            $('.header-topbar').removeClass('active');
        }
        $('.js-trigger-search').removeClass('d-none');
    });

    $('.header-menu__item--has-children').on('click', e => {
        if ($(e.currentTarget).hasClass('header-menu__item--has-children')) {
            if (window.innerWidth < 1379) {
                if (
                    !e.target.classList.contains(
                        'header-menu__link--many-title'
                    ) &&
                    !e.target.classList.contains('header-menu__depth-1-arrow')
                ) {
                    $(e.currentTarget).addClass('active');
                    $(e.currentTarget)
                        .find('.header-menu__megamenu')
                        .addClass('active');
                    $('.header-topbar').removeClass('active');
                }
            } else {
                $('.header-menu__item--has-children').each(function () {
                    $(this).removeClass('active');
                });

                $(e.currentTarget).addClass('active');
                $(e.currentTarget)
                    .find('.header-menu__megamenu')
                    .addClass('active');
                $('.header-topbar').removeClass('active');
            }
        }
    });

    $(document).on('click', function (e) {
        if (window.innerWidth < 1379) return;
        var container = $('.header-menu__item');
        const closestPopup = $(e.target).closest('.popup');

        if (
            !container.is(e.target) &&
            container.has(e.target).length === 0 &&
            $('.header').has(e.target).length === 0 &&
            closestPopup.length === 0
        ) {
            $('.header-menu__megamenu').hide();
            $('.header-menu__item').removeClass('active');
            isHovered = false;
            unActiveHeader();
        }
    });

    $('.header-menu__link--depth-1-single').on('click', e => {
        e.preventDefault();
        $('.header-menu__megamenu').removeClass('active');
        $('.header-menu__items-depth-2-wrapper--many').removeClass('active');
    });

    $('.header-menu__link--many-title').on('click', e => {
        e.preventDefault();

        const parentList = $(e.currentTarget).parent().parent().parent();
        if (parentList.is('.header-menu__item--depth-0, .active'))
            parentList.removeClass('active');

        $(e.currentTarget).parent().parent().removeClass('active');
        $('.header-topbar').addClass('active');
    });

    $('.header-menu__megamenu-item--many').on('click', e => {
        if ($(e.target).hasClass('header-menu__megamenu-item--many')) {
            $('.header-menu__items-depth-2-wrapper--many').removeClass(
                'active'
            );
            $(e.currentTarget)
                .find('.header-menu__items-depth-2-wrapper--many')
                .addClass('active');
        }
    });
}
