import videojs from 'video.js';
import '../../../../../../../node_modules/videojs-youtube/dist/Youtube.min.js';

export default class PopupVideo {
    constructor(el) {
        if (!this.set(el)) return;
        this.init();
    }

    set(el) {
        if (!el) return false;
        this.root = el;
        this.video = this.root.querySelector('[data-video-js]');
        this.duration = this.root.querySelector('[data-video-duration]');
        this.close = this.root.querySelector('[data-popup-close]');
        this.overlay = this.root.querySelector('[data-popup-overlay]');

        return true;
    }

    init() {
        this.setVideo();
        this.bindClose();
        this.bindOverlayClose();
    }

    setVideo() {
        if (!this.video) return;
        const video = videojs(this.video);
        const instance = this;

        /*video.one(
            'loadedmetadata',
            function () {
                const duration = video.duration();
                const minutes = this.setDuration(duration);

                console.log(video);
            }.bind(this)
        );*/

        video.ready(function(){
            this.on('loadedmetadata', function(){
                console.log('Loaded video...');
                const duration = video.duration();
                const minutes = instance.setDuration(duration);
            })
        });
    }

    setDuration(seconds) {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = parseInt(seconds % 60);
        const formattedHours = hours < 10 ? `0${hours}` : `${hours}`;
        const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
        const formattedSeconds =
            remainingSeconds < 10
                ? `0${remainingSeconds}`
                : `${remainingSeconds}`;
        const result2 = `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
        this.duration.innerHTML = result2;
    }

    bindOverlayClose() {
        if (!this.overlay) return;
        this.overlay.addEventListener('click', this.closePopup.bind(this));
    }

    bindClose() {
        if (!this.close) return;
        this.close.addEventListener('click', this.closePopup.bind(this));
    }

    closePopup() {
        this.root.remove();
    }
}
