export default function inputHandler() {
    $ = jQuery;

    $('input').on('input', function (event) {
        const input = $(this);

        if (input.val() !== '') {
            input.addClass('filled');
        } else {
            input.removeClass('filled');
        }
    });

    $('input').on('keydown', function (event) {
        const input = $(this);
        const type = input.attr('type');
        const { key } = event;
        
        if (type === 'number') {
            if (["e", "E", "+", "-"].includes(key) && event.preventDefault());
        }
    });

    $('textarea').on('input', function () {
        const input = $(this);

        if (input.val() !== '') {
            input.addClass('filled');
        } else {
            input.removeClass('filled');
        }
    });
}
